/* Hero Image */
.ServicesHero {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    animation: fadeIn 1.5s ease-in-out;
    margin-bottom: 0;
    padding-bottom: 0;
}

/* Fade-in animation */
@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.ServicesHero__MediaWrap {
    position: relative;
    width: 100%;
    height: 100%;
}

.ServicesHero__HeroImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: zoomIn 3s ease-in-out forwards;
}

@keyframes zoomIn {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(1.05);
    }
}

/* Services Description Section */
.ServicesDescription {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background-color: white;
    padding: 50px 0;
    margin-top: -10px;
    animation: fadeInUp 1s ease-in-out 3s;
}

@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

.ServicesDescription__Wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.ServicesDescription__Left {
    background-color: #9F9F9F;
    width: 30%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}
.ServicesDescription__VerticalText {
    font-size: 3rem;
    font-weight: bold;
    color: #fff;
    transform: rotate(-90deg);
    white-space: nowrap;
    letter-spacing: 10px;
}
.ServicesDescription__Right {
    width: 65%;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
    .ServicesHero {
        height: 80vh;
    }

    .ServicesDescription__Wrapper {
        flex-direction: column;
        padding: 0;
    }

    .ServicesDescription__Left,
    .ServicesDescription__Right {
        width: 100%;
        padding: 20px;
    }

    .ServicesHero__HeroImg img {
        object-fit: cover;
    }
}

@media (max-width: 768px) {
    .ServicesHero {
        height: 70vh;
        background-color: white;
    }

    .ServicesHero__MediaWrap {
        height: auto;
    }

    .ServicesHero__HeroImg img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }

    .ServicesDescription {
        padding: 20px 0;
        margin-top: -5px;
    }

    .ServicesDescription__Left {
        height: 200px;
        display: flex;
        justify-content: center;
    }
}

@media (max-width: 480px) {
    .ServicesHero {
        height: 60vh;
    }

    .ServicesHero__MediaWrap {
        height: auto;
    }

    .ServicesHero__HeroImg img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .ServicesDescription {
        padding: 20px 0;
        margin-top: -5px;
    }

    .ServicesDescription__Left {
        display: none;
        /* Hide the left section on mobile devices */
    }
}





/* Additional styles for the description */
.ServicesDescription__PopupContent h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: #333;
}

.ServicesDescription__PopupContent p {
    font-size: 1.125rem;
    line-height: 1.5;
    color: #666;
    margin-bottom: 15px;
}

/* Card Palette */
.ServicesDescription__Cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 20px;
    margin-top: 20px;
}

.service-card {
    background-color: #9F9F9F;
    padding: 50px;
    color: #fff;
    font-size: 1.25rem;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    text-align: start;
}

.service-card:hover {
    background-color: #91a644;
    cursor: pointer;
    transform: scale(1.05);
    box-shadow: 0 10px 15px rgba(0, 0, 0, 0.2);
}









/* Architecture and Engineering Sections */
.architecture-section,
.engineering-section {
    display: grid;
    align-items: center;
    justify-content: space-between;
    padding: 135px 0px;
    background-color: white;
    overflow: hidden;
    position: relative;
    grid-template-columns: repeat(22, 1fr);
    column-gap: 20px;
    row-gap: 55px;
}









/* Architecture Section Specific Styles */
.architecture-section .architecture-images {
    grid-column: 1 / 11;
    position: relative;
}

.architecture-section .architecture-text {
    grid-column: 12 / -1;
    order: 2;
    width: 100%;
    max-width: 475px;
    margin: 0 auto;
}

/* Engineering Section Specific Styles */
.engineering-section .engineering-images {
    grid-column: 13 / -1;
    position: relative;
}

.engineering-section .engineering-text {
    grid-column: 1 / 11;
    order: -1;
    width: 100%;
    max-width: 475px;
    margin: 0 auto;
}

/* Image Styles for Both Sections */
.architecture-images .image1,
.engineering-images .image1 {
    width: 550px;
    height: 550px;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
}

/* Positioning architecture image2 */
.architecture-images .image2 {
    width: 300px;
    height: 350px;
    position: absolute;
    top: -100px;
    left: -120px;
    box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
    clip-path: polygon(0 0, 100% 0, 78% 100%, 0% 100%);
    z-index: 1;
}

/* Positioning engineering image2 */
.engineering-images .image2 {
    width: 300px;
    height: 350px;
    position: absolute;
    top: -200px;
    right: -120px;
    box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
    --top-left: 15% 0;
    --top-right: calc(100% + 120px) 0;
    --btm-right: calc(100% + 120px) calc(100% + 160px);
    --btm-left: -2% calc(100% + 160px);
    clip-path: polygon(var(--top-left), var(--top-right), var(--btm-right), var(--btm-left));
    z-index: 1;
}

/* Update Architecture and Engineering Headings */
.architecture-text h2,
.engineering-text h2,
.clients-heading {
    font-size: 3.75rem;
    font-weight: 200;
    margin-bottom: 60px;
}

/* Services Featured Projects Section */
.services-featured-projects {
    font-family: inherit;
    background-color: #9F9F9F;
    color: #fff;
    padding: 200px 0px;
}

.slick-prev:before,
.slick-next:before {
    color: #fff;
}

.services-featured-projects h2 {
    display: inline-block;
    min-width: 650px;
    padding-right: 125px;
    padding-bottom: 60px;
    border-bottom: 2px solid rgb(255, 255, 255);
    color: inherit;
    font-family: inherit;
    font-weight: 200;
    line-height: 1.2;
    font-size: 2.5rem;
    margin-left: 2cm;
}

/* Style for each slide */
.services-slide {
    margin-top: 1cm;
    position: relative;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    transform: scale(0.9);
    background-color: transparent;
}

.services-slide.selected {
    transform: scale(1);
    background-color: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.services-slide.selected img {
    transform: scale(1);
    background-color: #fff;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    clip-path: polygon(-20% -20%, 100% -20%, 78% calc(100% + 160px), -120px calc(100% + 160px));
}

.services-slide img {
    width: 100%;
    height: auto;
}

.services-slide:not(.selected) img {
    clip-path: polygon(10% 0%, 90% 0%, 90% 100%, 10% 100%);
    opacity: 0.5;
}

.slick-center img {
    opacity: 1;
}

.services-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
}

.services-circle {
    width: 100px;
    height: 100px;
    border: 2px solid white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: white;
}

.services-search-icon {
    font-size: 36px;
    color: black;
    position: absolute;
    bottom: 10px;
    right: 10px;
}

.services-slide:hover .services-overlay {
    opacity: 1;
    visibility: visible;
}

.services-slide:hover .services-overlay .services-circle {
    animation: services-fadeIn 0.3s;
}

@keyframes services-fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
    .services-featured-projects h2 {
        font-size: 2rem;
        min-width: 100%;
        padding-right: 0;
        text-align: center;
        margin-left: 0;
    }

    .services-slide.selected {
        padding: 10px;
    }

    .services-slide.selected img {
        padding: 5px;
    }

    .services-slide {
        transform: scale(1);
    }
}

@media (max-width: 768px) {
    .services-featured-projects h2 {
        font-size: 1.8rem;
        padding-bottom: 30px;
        margin-left: 0;
    }

    .services-slide {
        transform: scale(0.95);
        margin-top: 0.5cm;
    }

    .services-slide.selected {
        padding: 10px;
    }

    .services-slide.selected img {
      clip-path: polygon(-20% -20%, 100% -20%, 78% calc(100% + 160px), -120px calc(100% + 160px));
        padding: 5px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
}

@media (max-width: 480px) {
    .services-featured-projects {
        padding: 100px 0;
    }

    .services-featured-projects h2 {
        font-size: 1.5rem;
        text-align: center;
        padding-bottom: 20px;
        min-width: 100%;
    }

    .services-slide {
        transform: scale(0.9);
        margin-top: 0.5cm;
        margin-bottom: 1.5cm;
    }

    .services-slide img {
        width: 100%;
    }

    .services-slide.selected img {
        padding: 0;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.15);
    }

    .services-circle {
        width: 80px;
        height: 80px;
        font-size: 16px;
    }

    .services-search-icon {
       
        font-size: 20px;
    }
}








/* Creative Layered Section Styles */
.creative-layered-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    /* Adjusted height */
    background-color: white;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-bottom: 40px;
    /* Extra space below the parallelograms */
}

.parallelogram-layered {
    width: 180px;
    height: 100px;
    margin-right: -30px;
    /* Reduced gap to bring them closer */
    opacity: 0.9;
    position: absolute;
    transform: skew(-20deg) rotate(5deg);
    /* Skew and rotation */
    transition: transform 0.5s ease, opacity 0.5s ease;
    z-index: 1;
}

.parallelogram-layered:hover {
    transform: skew(-20deg) rotate(0deg) scale(1.1);
    /* Hover effect */
    opacity: 1;
}

/* Colors for each parallelogram */
.layered-1 {
    background-color: #ccd96c;
    top: 50px;
    left: 8%;
    z-index: 2;
}

.layered-2 {
    background-color: #8bbbd9;
    top: 70px;
    left: 18%;
    z-index: 1;
}

.layered-3 {
    background-color: #c9d955;
    top: 45px;
    left: 28%;
    z-index: 3;
}

.layered-4 {
    background-color: #b5bf65;
    top: 65px;
    left: 38%;
    z-index: 2;
}

.layered-5 {
    background-color: #91a644;
    top: 60px;
    left: 48%;
    z-index: 1;
}

.layered-6 {
    background-color: #d7d9a0;
    top: 55px;
    left: 58%;
    z-index: 3;
}

.layered-7 {
    background-color: #66caf2;
    top: 70px;
    left: 68%;
    z-index: 2;
}

.layered-8 {
    background-color: #296573;
    top: 60px;
    left: 78%;
    z-index: 1;
}

/* Hover effects */
.layered-1:hover,
.layered-3:hover,
.layered-5:hover,
.layered-7:hover {
    transform: skew(-20deg) rotate(-5deg) scale(1.1);
    /* Rotate in opposite direction */
}

.layered-2:hover,
.layered-4:hover,
.layered-6:hover,
.layered-8:hover {
    transform: skew(-20deg) rotate(5deg) scale(1.1);
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
    .parallelogram-layered {
        width: 140px;
        height: 80px;
        margin-right: -20px;
        /* Reduced gap for smaller screens */
    }
}

@media (max-width: 768px) {
    .parallelogram-layered {
        width: 100px;
        height: 60px;
        margin-right: -15px;
        /* Further reduced gap for small screens */
    }
}

@media (max-width: 480px) {
    .parallelogram-layered {
        width: 80px;
        height: 50px;
        margin-right: -10px;
        /* Very close spacing for mobile screens */
    }
}


















/* Our Clients Section */
.our-clients {
    padding: 80px 20px;
    background: rgb(240, 240, 240);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.clients-heading {
    font-size: 3.75rem;
    font-weight: 200;
    margin-bottom: 60px;
}

.clients-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 0 -15px;
    text-align: center;
}

.client-name {
    display: inline-block;
    color: rgb(201, 201, 201);
    font-size: 2.25rem;
    font-weight: 200;
    line-height: 1.7;
    white-space: nowrap;
    margin: 0 10px;
}

.client-name.highlight {
    color: rgb(0, 0, 0);
    font-weight: 200;
    transition: opacity 0.35s ease-in;
}

/* WorkWithUs.css */
.work-with-us {
    padding: 50px 10px 125px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1cm;
    box-sizing: border-box;
}

.work-with-us-container {
    display: flex;
    max-width: 1200px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.work-with-us-image img {
    width: 100%;
    height: 100%;
    max-width: 500px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
    clip-path: polygon(-20% -20%, 100% -20%, 58% calc(100% + 160px), -120px calc(100% + 160px));
}

.work-with-us-content {
    max-width: 600px;
    text-align: left;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.work-with-us-heading {
    font-size: 3.75rem;
    font-weight: 200;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.2;
}

.work-with-us-text {
    font-size: 1.25rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #333;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    width: 100%;
}

.work-with-us-text li {
    margin-bottom: 0.2rem;
}

.work-with-us-button2 {
    display: inline-block;
    padding: 10px 20px;
    background-color: #91A644;
    color: #fff;
    text-decoration: none;
    font-size: 1.125rem;
    font-weight: 500;
    margin-top: 40px;
    text-align: center;
    width: 50%;
    transition: background-color 0.3s;
    
}

.work-with-us-button:hover {
    background-color: #91A644;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .work-with-us-container {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        /* Reduced padding for smaller screens */
    }

    .work-with-us-image img {
        max-width: 90%;
        /* Increased max-width for better visibility */
        margin-bottom: 30px;
    }

    .work-with-us-content {
        padding-left: 0;
        align-items: flex-start;
        text-align: left;
    }

    .work-with-us-heading {
        font-size: 2.5rem;
        text-align: left;
        margin-left: 0;
        padding-left: 10px;
        /* Aligned with the container */
    }

    .work-with-us-text {
        grid-template-columns: 1fr;
        /* Stack items vertically */
        gap: 5px;
    }

    .work-with-us-button {
        font-size: 1rem;
        padding: 50px;
        width: 100%;
        
        /* Full width for better alignment */
        margin-left: 0;
    }
}

@media screen and (max-width: 480px) {
    .work-with-us-container {
        padding: 5px;
        /* Minimal padding for very small screens */
    }

    .work-with-us-heading {
        font-size: 2rem;
        padding-left: 5px;
    }

    .work-with-us-text {
        font-size: 1rem;
    }

    .work-with-us-button {
        font-size: 0.875rem;
           padding: 50px;
        width: 100%;
        /* Full width for small devices */
    }
}











/* Responsive adjustments for mobile devices */
@media screen and (max-width: 768px) {

    /* Hero Section */
 

    /* Architecture and Engineering Sections */
    .architecture-section,
    .engineering-section {
        grid-template-columns: 1fr;
        padding: 40px 20px;
    }

    .architecture-section .architecture-images,
    .engineering-section .engineering-images {
        grid-column: 1 / -1;
        position: relative;
        margin-bottom: 20px;
    }

    .architecture-images .image1,
    .engineering-images .image1 {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 2;
        box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
        clip-path: polygon(0 0, 100% 0, 78% 100%, 0% 100%);
    }

    .architecture-images .image2,
    .engineering-images .image2 {
        display: none;
    }

    .architecture-section .architecture-text,
    .engineering-section .engineering-text {
        grid-column: 1 / -1;
        text-align: left;
        margin-top: 20px;
    }

    /* Hide Featured Projects Section on Mobile */
   

    /* Clients Section */
    .our-clients {
        padding: 60px 10px;
    }

    .clients-heading,
    .architecture-text h2,
    .engineering-text h2 {
        font-size: 2.5rem;
        margin-bottom: 30px;
    }

    .client-name {
        font-size: 1.75rem;
        margin: 0 5px;
    }

    .clients-row {
        margin: 0 -5px;
        gap: 10px;
    }

   
}

/* Extra small devices (phones) */
@media screen and (max-width: 480px) {


    /* Architecture and Engineering Sections */
    .architecture-images .image1,
    .engineering-images .image1 {
        width: 90%;
        margin: 0 auto;
    }

    .architecture-images .image2,
    .engineering-images .image2 {
        display: none;
    }

    .architecture-section .architecture-text,
    .engineering-section .engineering-text {
        text-align: left;
        margin-top: 15px;
    }



    /* Clients Section */
    .clients-heading,
    .architecture-text h2,
    .engineering-text h2 {
        font-size: 2rem;
        margin-bottom: 20px;
    }

    .client-name {
        font-size: 1.5rem;
    }

    .clients-row {
        gap: 8px;
    }

    
}
