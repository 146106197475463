/* General Styles */
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.home-page {
    width: 100%;
    overflow-x: hidden;
}

/* Header */
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: white;
    z-index: 1200;
    height: 70px;
}

.appbar {
    box-shadow: none;
    z-index: 1100;
    height: 70px;
    background-color: #66b8d9;
}

.logo img {
    height: 50px;
    max-width: 100%;
}

.social-media-icons {
    display: flex;
    gap: 10px;
    margin-left: auto;
    margin-right: 1rem;
}

.social-media-icons a {
    color: black;
    font-size: 1.2rem;
    transition: color 0.3s ease;
}

.social-media-icons a:hover {
    color: #459dbf;
}

.menu-icon {
    font-size: 2rem;
    color: #4b4947;
}

.nav-container {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background-color: white;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s ease-out;
    z-index: 2000;
}

.nav-container.open {
    max-height: 300vh;
    transition: max-height 0.4s ease-in;
}

.nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-item {
    margin: 15px 0;
    font-size: 1.25rem;
}

/* Responsive Styles for Mobile */
@media screen and (max-width: 768px) {
    .logo img {
        height: 40px;
        /* Reduce logo size on smaller screens */
    }

    .social-media-icons a {
        font-size: 1rem;
        /* Reduce icon size on smaller screens */
    }

    .menu-icon {
        font-size: 1.8rem;
        /* Slightly reduce menu icon size on smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .logo img {
        height: 35px;
        /* Further reduce logo size on very small screens */
    }

    .social-media-icons a {
        font-size: 0.9rem;
        /* Further reduce icon size on very small screens */
    }

    .menu-icon {
        font-size: 1.6rem;
        /* Further reduce menu icon size on very small screens */
    }
}



/* Hero Container */
.hero-container {
    width: 100%;
    height: 100vh;
    /* Full height on large screens */
    overflow: hidden;
    position: relative;
    margin: 0;
    padding: 0;
    background-color: #9F9F9F;
}

.hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 1s ease-in-out;
}

/* Mobile Responsiveness for Hero Container */
@media screen and (max-width: 768px) {
    .hero-container {
        height: 30vh;
        background-color: #9F9F9F;
        /* Adjust the height for tablets */
    }

    .hero-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        /* Ensure the image covers the entire container */
    }
}

@media screen and (max-width: 480px) {
    .hero-container {
        height: 30vh;
        background-color: #9F9F9F;
        /* Adjust the height for mobile phones */
    }

    .hero-image {
        height: 100%;
        width: 100%;
        object-fit: cover;
        /* Ensure the image covers the entire container */
    }
}


/* Featured Projects */
.featured-projects-2 {
    padding: 200px 0px;
    /* Reduce padding on top */
    background-color: #9F9F9F;
    color: white;
    text-align: left;
    margin: 0;
    /* Ensure no margin at the top */
}

@media screen and (max-width: 1379px) {
    .featured-projects-2 {
        padding: 160px 0px;
    }
}

.featured-projects-2 h2 {
    color: white;
    font-size: 2.5rem;
    font-weight: 200;
    margin-bottom: 40px;
    display: inline-block;
    min-width: 650px;
    padding-right: 125px;
    padding-bottom: 20px;
    border-bottom: 2px solid white;
    margin-bottom: 90px;
    margin-left: 0.5cm;
}

@media screen and (max-width: 768px) {
    .featured-projects-2 {
        padding: 70px 0px;
        /* Further reduce padding on smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .featured-projects-2 {
        padding: 50px 0px;
        /* Minimal padding for very small screens */
    }
}

/* Remove unwanted space on mobile devices */
@media screen and (max-width: 768px) {
    .featured-projects-2 {
        margin-top: -20px !important;
        /* Remove additional margin space */
    }
}

@media screen and (max-width: 480px) {
    .featured-projects-2 {
        margin-top: -20px !important;
        /* Remove additional margin space */
    }
}

/* Slick Slider */
.slick-slider {
    width: 100%;
    margin: 0 auto;
    position: relative;
}

.slick-slide {
    padding: 0 10px;
    box-sizing: border-box;
    opacity: 0.5;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: scale(0.8);
    position: relative;
    z-index: 1;
}

.slick-slide.slick-center,
.slick-slide.selected {
    opacity: 1;
    z-index: 2;
    transform: scale(1);
}

.image-container {
    position: relative;
    width: 400px;
    height: 250px;
    background-color: white;
    padding: 0px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    margin: 0 auto;
    box-sizing: border-box;
    transition: all 0.3s ease;
}

.selected-container {
    width: 480px;
    height: 340px;
    padding: 30px;
}

.clipped-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.3s ease;
}

.cropped {
    clip-path: polygon(-20% -20%, 100% -20%, 78% calc(100% + 160px), -120px calc(100% + 160px));
}

.search-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 24px;
    color: #000;
    background: white;
    border-radius: 50%;
    padding: 8px;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}

.image-container::before {
    content: "DRAG";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    color: white;
    background: transparent;
    border: 2px solid white;
    border-radius: 50%;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
}

.image-container:hover::before {
    opacity: 1;
}

@media screen and (max-width: 1024px) {
    .slick-slide {
        padding: 0 5px;
    }

    .image-container {
        width: 300px;
        height: 200px;
    }

    .selected-container {
        width: 380px;
        height: 280px;
        padding: 25px;
    }

    .cropped {
        clip-path: polygon(-15% -15%, 100% -15%, 78% calc(100% + 120px), -90px calc(100% + 120px));
    }

    .image-container::before {
        width: 80px;
        height: 80px;
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 768px) {
    .image-container {
        width: 280px;
        height: 180px;
    }

    .selected-container {
        width: 340px;
        height: 240px;
        padding: 20px;
    }

    .search-icon {
        font-size: 20px;
    }

    .image-container::before {
        width: 70px;
        height: 70px;
        font-size: 0.75rem;
    }
}








/* Hero Section */
.hero-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    background-color: #f5f5f5;
}

.hero-section-content {
    max-width: 50%;
}

.hero-section-content h1 {
    margin: 0;
    color: inherit;
    font-family: inherit;
    font-weight: 200;
    line-height: 1.2;
    font-size: 3.75rem;
    margin-left: 4cm;
    margin-right: 3cm;
}

.hero-section-content p {
    margin-top: 4cm;
    font-size: 1.2rem;
    line-height: 1.5;
    margin-left: 12cm;
    margin-right: -6cm;
}

.hero-section-video {
    position: relative;
    max-width: 25%;
    height: 500px;
    overflow: hidden;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 4px 8px rgba(236, 233, 233, 0.2);
    --top-left: 40% -20%;
    --top-right: 100% -20%;
    --btm-right: 100% 150%;
    --btm-left: 0% 140%;
    clip-path: polygon(var(--top-left), var(--top-right), var(--btm-right), var(--btm-left));
}

.hero-section-video video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: scale(1.2);
}

@media (max-width: 1024px) {
    .hero-section {
        flex-direction: column;
        padding: 2rem;
        text-align: center;
    }

    .hero-section-content {
        max-width: 100%;
        margin: 0;
    }

    .hero-section-content h1 {
        font-size: 3rem;
        margin-left: 0;
        margin-right: 0;
    }

    .hero-section-content p {
        margin-top: 2rem;
        font-size: 1.1rem;
        margin-left: 0;
        margin-right: 0;
    }

    .hero-section-video {
        max-width: 100%;
        height: auto;
        margin-top: 2rem;
    }

    .hero-section-video video {
        height: auto;
    }
}

@media (max-width: 768px) {
    .hero-section-content h1 {
        font-size: 2.5rem;
    }

    .hero-section-content p {
        font-size: 1rem;
        margin-top: 1.5rem;
    }

    .hero-section-video {
        height: 300px;
    }
}

@media (max-width: 480px) {
    .hero-section-content h1 {
        font-size: 2rem;
    }

    .hero-section-content p {
        font-size: 0.9rem;
    }

    .hero-section-video {
        height: 200px;
    }
}

/* About Us Section */
.about-us {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: #f5f5f5;
}

.about-us-content {
    text-align: center;
    width: 100%;
}

.about-us-content button {
    background-color: #9F9F9F;
    color: rgb(255, 255, 255);
    font-family: Oscine, sans-serif;
    font-size: 0.875rem;
    text-align: center;
    transition: background-color 500ms ease;
    position: relative;
    display: inline-block;
    line-height: 1;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
    padding: 20px 90px;
    margin-top: 80px;
    margin-bottom: 80px;
}

.about-us-images {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
    margin-top: -300px;
    clip-path: polygon(var(--top-left), var(--top-right), var(--btm-right), var(--btm-left));
}

.about-us-images img {
    max-width: 100%;
    border-radius: 8px;
    margin-bottom: 1rem;
    margin-top: 60px;
    height: auto;
    box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
    --top-left: -20% -20%;
    --top-right: 100% -20%;
    --btm-right: 58% calc(100% + 160px);
    --btm-left: -120px calc(100% + 160px);
    clip-path: polygon(var(--top-left), var(--top-right), var(--btm-right), var(--btm-left));
}

@media (max-width: 1024px) {
    .about-us-images {
        width: 70%;
        margin-top: -200px;
    }

    .about-us-content button {
        padding: 15px 70px;
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

@media (max-width: 768px) {
    .about-us-images {
        width: 90%;
        margin-top: -150px;
    }

    .about-us-content button {
        padding: 12px 50px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media (max-width: 480px) {
    .about-us-images {
        width: 100%;
        margin-top: -100px;
    }

    .about-us-content button {
        padding: 10px 30px;
        margin-top: 30px;
        margin-bottom: 30px;
        font-size: 0.75rem;
    }
}

/* Additional Images Section */
.additional-images {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 100%;
    background-color: #f5f5f5;
    position: relative;
    margin-top: 2rem;
}

.additional-images-wrapper {
    position: relative;
    width: 70%;
    max-width: 1200px;
}

.large-image {
    width: 100%;
    border-radius: 1px;
    margin: 0;
    padding: 0;
}

.small-image {
    position: absolute;
    top: -2cm;
    left: 7%;
    width: 30%;
    height: 35%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    object-fit: cover;
    clip-path: polygon(var(--top-left), var(--top-right), var(--btm-right), var(--btm-left));
}

@media screen and (max-width: 1024px) {
    .additional-images-wrapper {
        width: 80%;
    }

    .small-image {
        top: -1.5cm;
        left: 6%;
        width: 35%;
        height: auto;
    }
}

@media screen and (max-width: 768px) {
    .additional-images-wrapper {
        width: 90%;
    }

    .small-image {
        top: -1cm;
        left: 5%;
        width: 40%;
        height: auto;
    }
}

@media screen and (max-width: 480px) {
    .additional-images-wrapper {
        width: 100%;
        padding: 0 10px;
    }

    .small-image {
        top: 0;
        left: 3%;
        width: 45%;
        height: auto;
    }
}

@media screen and (min-width: 1024px) {
    .additional-images {
        margin-top: 3rem;
    }
}

@media screen and (max-width: 768px) {
    .additional-images {
        margin-top: 1.5rem;
    }
}

@media screen and (max-width: 480px) {
    .additional-images {
        margin-top: 1rem;
    }
}

/* What We Offer Section */
.Section-sc-1vjgz5n-0.gMfwoD {
    padding: 135px 0px;
    background-color: rgb(240, 240, 240);
    width: 100%;
}

.Container-sc-1uyjznq-0.eBZEyY {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.Grid-sc-1gc00t8-0.fakLAl {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Grid__Item-sc-1gc00t8-1.CenteredContent__ContentWrap-sc-yfj9nq-0.lbvRqf {
    text-align: center;
    max-width: 800px;
}

.H__H1-sc-be5gk5-0.HomeIndustries__IndustryHeading-sc-12x8pk9-1.eGDaCZ {
    margin: 0px;
    color: inherit;
    font-family: inherit;
    font-weight: 200;
    line-height: 1.2;
    font-size: 3.75rem;
    margin-bottom: 35px;
}

p {
    font-family: Oscine, sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(97, 97, 97);
    margin: 0px;
    text-align: left;
}

.ImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
}

.OfferImage {
    max-width: 100%;
    height: auto;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.Grid__Item-sc-1gc00t8-1.HomeIndustries__MainIndustryContent-sc-12x8pk9-4.evdcvr {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 800px;
    margin-top: 2rem;
}

.H__H4-sc-be5gk5-2.HomeIndustries__MainIndustryHeading-sc-12x8pk9-3.hVZaQy {
    margin-top: 2cm;
    color: inherit;
    font-family: inherit;
    font-weight: 200;
    line-height: 1.2;
    font-size: 2.25rem;
    margin-bottom: 35px;
}

.StyledLink-sc-zurfbq-0.vQgHw {
    display: inline-block;
    margin-top: 10px;
    font-size: 1rem;
    color: rgba(61, 60, 60, 0.2);
    text-decoration: none;
}

.StyledLink-sc-zurfbq-0.vQgHw:hover {
    text-decoration: underline;
}

@media screen and (max-width: 1024px) {
    .H__H1-sc-be5gk5-0.HomeIndustries__IndustryHeading-sc-12x8pk9-1.eGDaCZ {
        font-size: 3rem;
    }

    .H__H4-sc-be5gk5-2.HomeIndustries__MainIndustryHeading-sc-12x8pk9-3.hVZaQy {
        font-size: 2rem;
    }

    .p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .Section-sc-1vjgz5n-0.gMfwoD {
        padding: 80px 0px;
    }

    .H__H1-sc-be5gk5-0.HomeIndustries__IndustryHeading-sc-12x8pk9-1.eGDaCZ {
        font-size: 2.5rem;
        margin-bottom: 25px;
    }

    .H__H4-sc-be5gk5-2.HomeIndustries__MainIndustryHeading-sc-12x8pk9-3.hVZaQy {
        font-size: 1.75rem;
        margin-bottom: 25px;
    }
}

@media screen and (max-width: 480px) {
    .Section-sc-1vjgz5n-0.gMfwoD {
        padding: 60px 0px;
    }

    .H__H1-sc-be5gk5-0.HomeIndustries__IndustryHeading-sc-12x8pk9-1.eGDaCZ {
        font-size: 2rem;
        margin-bottom: 20px;
    }

    .H__H4-sc-be5gk5-2.HomeIndustries__MainIndustryHeading-sc-12x8pk9-3.hVZaQy {
        font-size: 1.5rem;
        margin-bottom: 20px;
    }

    .p {
        font-size: 0.875rem;
    }
}

/* Business and Education Section */
.Container-sc-1uyjznq-0.eBZEyY {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
}

.Grid-sc-1gc00t8-0.HomeIndustries__IndustriesGrid-sc-12x8pk9-5.eavKAV {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Grid__Item-sc-1gc00t8-1.HomeIndustries__IndustryItem-sc-12x8pk9-6.hzBbuM {
    width: 45%;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.2);
    background-color: #f5f5f5;
    border-radius: 1px;
    overflow: hidden;
    margin-bottom: 2rem;
}

.AngledImg__StyledAngledImg-sc-l04nmq-0.eedwbc.HomeIndustries__IndustryImg-sc-12x8pk9-7.iexjdx {
    overflow: hidden;
}

.RatioImg__Wrapper-sc-8qesjk-1.fZxNrn {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 55%;
}

.RatioImg__Img-sc-8qesjk-0.dwEpAz {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    clip-path: polygon(-20% -20%, 100% -20%, 78% calc(100% + 160px), -120px calc(100% + 160px));
}

.HomeIndustries__IndustryContent-sc-12x8pk9-8.jKMlSJ {
    margin-top: 2cm;
    padding: 0rem;
}

.H__H4-sc-be5gk5-2.StwfE {
    margin: 0px;
    color: inherit;
    font-family: inherit;
    font-weight: 200;
    line-height: 1.2;
    display: block;
    font-size: 2.25rem;
}

.HomeIndustries__IndustryCopy-sc-12x8pk9-9.fywiye {
    margin: 30px 0px 35px;
    font-family: Oscine, sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(97, 97, 97);
}

.StyledLink-sc-zurfbq-0.vQgHw {
    font-family: Quiroh, sans-serif;
    font-size: 1.125rem;
    position: relative;
    display: inline-block;
    line-height: 1;
    font-weight: 400;
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.StyledLink-sc-zurfbq-0.vQgHw:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .Grid-sc-1gc00t8-0.HomeIndustries__IndustriesGrid-sc-12x8pk9-5.eavKAV {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Grid__Item-sc-1gc00t8-1.HomeIndustries__IndustryItem-sc-12x8pk9-6.hzBbuM {
        width: 100%;
        margin-bottom: 1.5rem;
    }

    .H__H4-sc-be5gk5-2.StwfE {
        font-size: 1.75rem;
    }

    .HomeIndustries__IndustryCopy-sc-12x8pk9-9.fywiye {
        font-size: 1rem;
        margin: 20px 0px 30px;
    }

    .StyledLink-sc-zurfbq-0.vQgHw {
        font-size: 1rem;
    }

    .RatioImg__Wrapper-sc-8qesjk-1.fZxNrn {
        padding-bottom: 60%;
    }
}




/* Creative Layered Section Styles */
.creative-layered-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    /* Adjusted height */
    background-color: #f5f5f5;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-bottom: 40px;
    /* Extra space below the parallelograms */
}

.parallelogram-layered {
    width: 180px;
    height: 100px;
    margin-right: -30px;
    /* Reduced gap to bring them closer */
    opacity: 0.9;
    position: absolute;
    transform: skew(-20deg) rotate(5deg);
    /* Skew and rotation */
    transition: transform 0.5s ease, opacity 0.5s ease;
    z-index: 1;
}

.parallelogram-layered:hover {
    transform: skew(-20deg) rotate(0deg) scale(1.1);
    /* Hover effect */
    opacity: 1;
}

/* Colors for each parallelogram */
.layered-1 {
    background-color: #ccd96c;
    top: 50px;
    left: 8%;
    z-index: 2;
}

.layered-2 {
    background-color: #8bbbd9;
    top: 70px;
    left: 18%;
    z-index: 1;
}

.layered-3 {
    background-color: #c9d955;
    top: 45px;
    left: 28%;
    z-index: 3;
}

.layered-4 {
    background-color: #b5bf65;
    top: 65px;
    left: 38%;
    z-index: 2;
}

.layered-5 {
    background-color: #91a644;
    top: 60px;
    left: 48%;
    z-index: 1;
}

.layered-6 {
    background-color: #d7d9a0;
    top: 55px;
    left: 58%;
    z-index: 3;
}

.layered-7 {
    background-color: #66caf2;
    top: 70px;
    left: 68%;
    z-index: 2;
}

.layered-8 {
    background-color: #296573;
    top: 60px;
    left: 78%;
    z-index: 1;
}

/* Hover effects */
.layered-1:hover,
.layered-3:hover,
.layered-5:hover,
.layered-7:hover {
    transform: skew(-20deg) rotate(-5deg) scale(1.1);
    /* Rotate in opposite direction */
}

.layered-2:hover,
.layered-4:hover,
.layered-6:hover,
.layered-8:hover {
    transform: skew(-20deg) rotate(5deg) scale(1.1);
}

/* Responsive Adjustments */
@media (max-width: 1024px) {
    .parallelogram-layered {
        width: 140px;
        height: 80px;
        margin-right: -20px;
        /* Reduced gap for smaller screens */
    }
}

@media (max-width: 768px) {
    .parallelogram-layered {
        width: 100px;
        height: 60px;
        margin-right: -15px;
        /* Further reduced gap for small screens */
    }
}

@media (max-width: 480px) {
    .parallelogram-layered {
        width: 80px;
        height: 50px;
        margin-right: -10px;
        /* Very close spacing for mobile screens */
    }
}






















/* Our Clients Section */
.our-clients {
    padding: 80px 20px;
    background: rgb(240, 240, 240);
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.clients-heading {
    font-size: 3.75rem;
    font-weight: 200;
    margin-bottom: 60px;
}

.clients-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
   
    text-align: center;
}

.client-name {
    display: inline-block;
    color: rgb(201, 201, 201);
    font-size: 2.25rem;
    font-weight: 200;
    line-height: 1.7;
    white-space: nowrap;
    margin: 0 10px;
}

.client-name.highlight {
    color: rgb(0, 0, 0);
    font-weight: 200;
    transition: opacity 0.35s ease-in;
}

@media (max-width: 1024px) {
    .clients-heading {
        font-size: 3rem;
        margin-bottom: 50px;
    }

    .client-name {
        font-size: 2rem;
        margin: 0 8px;
    }

    .clients-row {
        margin: 0 -10px;
    }
}

@media (max-width: 768px) {
    .clients-heading {
        font-size: 2.5rem;
        margin-bottom: 40px;
    }

    .client-name {
        font-size: 1.75rem;
        margin: 0 6px;
    }

    .clients-row {
        margin: 0 -8px;
    }
}

@media (max-width: 480px) {
    .clients-heading {
        font-size: 2rem;
        margin-bottom: 30px;
    }

    .client-name {
        font-size: 1.5rem;
        margin: 0 4px;
        white-space: normal;
    }

    .clients-row {
        margin: 0 -5px;
        gap: 10px;
    }
}
