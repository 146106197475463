/* Page transition animations */
.page-transition-enter {
  opacity: 0;
  position: relative;
}

.page-transition-enter-active {
  opacity: 1;
  transition: opacity 600ms ease-in;
}

.page-transition-exit {
  opacity: 1;
  position: relative;
}

.page-transition-exit-active {
  opacity: 0;
  transition: opacity 600ms ease-out;
}

/* Container for diagonal bar animation */
.diagonal-bars {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

/* Individual diagonal bars with creative enhancements */
.diagonal-bar {
  width: 25%;
  height: 100%;
  background: linear-gradient(135deg,
      rgba(69, 157, 191, 0.8),
      rgba(162, 191, 57, 0.8));
  transform: skewX(45deg) translateX(-100%) scale(1.2);
  animation: slideInDiagonalBars 600ms forwards ease-in, fadeInBlur 600ms ease-in;
  opacity: 0.7;
  filter: blur(2px);
}

.diagonal-bar:nth-child(2) {
  background: linear-gradient(135deg,
      rgba(162, 191, 57, 0.8),
      rgba(153, 166, 55, 0.8));
  animation-delay: 100ms;
}

.diagonal-bar:nth-child(3) {
  background: linear-gradient(135deg,
      rgba(153, 166, 55, 0.8),
      rgba(183, 191, 122, 0.8));
  animation-delay: 200ms;
}

.diagonal-bar:nth-child(4) {
  background: linear-gradient(135deg,
      rgba(183, 191, 122, 0.8),
      rgba(69, 157, 191, 0.8));
  animation-delay: 300ms;
}

/* Animation for sliding the diagonal bars in */
@keyframes slideInDiagonalBars {
  0% {
    transform: skewX(45deg) translateX(-100%) scale(1.2);
  }

  100% {
    transform: skewX(45deg) translateX(0) scale(1);
  }
}

/* Animation for sliding the diagonal bars out */
@keyframes slideOutDiagonalBars {
  0% {
    transform: skewX(45deg) translateX(0) scale(1);
  }

  100% {
    transform: skewX(45deg) translateX(100%) scale(1.2);
  }
}

/* Fade-in and blur animation for additional style */
@keyframes fadeInBlur {
  0% {
    opacity: 0;
    filter: blur(8px);
  }

  100% {
    opacity: 0.7;
    filter: blur(2px);
  }
}

/* Exit animation for the diagonal bars */
.diagonal-bars-exit .diagonal-bar {
  animation: slideOutDiagonalBars 600ms forwards ease-out, fadeOutBlur 600ms ease-out;
}

/* Fade-out and blur for creative exit */
@keyframes fadeOutBlur {
  0% {
    opacity: 0.7;
    filter: blur(2px);
  }

  100% {
    opacity: 0;
    filter: blur(8px);
  }
}

/* Mobile Responsive Adjustments */

/* For screens smaller than 768px (tablets and small devices) */
@media screen and (max-width: 768px) {
  .diagonal-bar {
    width: 35%;
    animation-duration: 500ms;
    /* Slightly faster animation for mobile */
    filter: blur(1.5px);
    /* Less intense blur effect */
  }

  .diagonal-bar:nth-child(2) {
    animation-delay: 80ms;
  }

  .diagonal-bar:nth-child(3) {
    animation-delay: 160ms;
  }

  .diagonal-bar:nth-child(4) {
    animation-delay: 240ms;
  }
}

/* For screens smaller than 480px (phones) */
@media screen and (max-width: 480px) {
  .diagonal-bar {
    width: 45%;
    animation-duration: 450ms;
    /* Faster animation for smaller devices */
    filter: blur(1px);
    /* Reduce blur intensity */
  }

  .diagonal-bar:nth-child(2) {
    animation-delay: 60ms;
  }

  .diagonal-bar:nth-child(3) {
    animation-delay: 120ms;
  }

  .diagonal-bar:nth-child(4) {
    animation-delay: 180ms;
  }
}
