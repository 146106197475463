:root {
    --color-heading: #333333;
    /* Dark Ash for heading */
    --color-text: #99A637;
    /* Olive Green for text */
    --color-container: #D9D5D2;
    /* Light Ash for container */
    --color-primary: #459DBF;
    /* Blue */
    --color-secondary: #A2BF39;
    /* Light Green */
    --color-tertiary: #99A637;
    /* Olive Green */
    --color-quaternary: #B7BF7A;
    /* Beige Green */
}

body {
    transition: background-color 0.5s ease, color 0.5s ease;
    
    /* Smooth transitions for background and text colors */
}

.appbar {
    
    box-shadow: none;
    z-index: 1100;
    height: 70px;
    background-color: var(--color-primary);
    transition: background-color 0.5s ease;
    /* Smooth transition for appbar */
}

.nav-container {
    
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background-color: var(--color-container);
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s ease-out;
    z-index: 2000;
}

.nav-container.open {
    max-height: 300vh;
    transition: max-height 0.4s ease-in;
}

.nav-item a {
    color: var(--color-heading);
    text-decoration: none;
    transition: color 0.3s ease;
    /* Smooth transition for link color */
}

.nav-item a:hover {
    color: var(--color-secondary);
    /* Change color on hover */
}

.menu-icon {
    font-size: 2rem;
    color: var(--color-heading);
}

.social-media-icons a {
    color: var(--color-text);
    transition: color 0.3s ease;
    /* Smooth transition for social icon color */
}

.social-media-icons a:hover {
    color: var(--color-quaternary);
}

.page-transition-enter {
    opacity: 0;
    transform: translateX(100%);
}

.page-transition-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 500ms ease-in, transform 500ms ease-in;
}

.page-transition-exit {
    opacity: 1;
    transform: translateX(0%);
}

.page-transition-exit-active {
    opacity: 0;
    transform: translateX(-100%);
    transition: opacity 500ms ease-out, transform 500ms ease-out;
}

/* Responsive Styles for Mobile */
@media screen and (max-width: 768px) {
    .logo img {
        height: 40px;
        /* Reduce logo size on smaller screens */
    }

    .social-media-icons a {
        font-size: 1rem;
    }

    .menu-icon {
        font-size: 1.8rem;
    }

    .nav-item {
        font-size: 1.15rem;
    }
}

@media screen and (max-width: 480px) {
    .logo img {
        height: 35px;
    }

    .social-media-icons a {
        font-size: 0.9rem;
    }

    .menu-icon {
        font-size: 1.6rem;
    }

    .nav-item {
        font-size: 1rem;
    }
}
