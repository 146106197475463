/* General Styles */
.project-hero {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4rem 2rem;
    background-color: rgb(240, 240, 240);
    
    flex-direction: row;
  height: 60vw;
    margin-bottom: 170px;
}

.hero-image-container {
    position: relative;
    flex: 2;
    margin-right: -2cm;
    height: 100%;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
    --top-left: 15% 0;
    --top-right: calc(100% + 120px) 0;
    --btm-right: calc(100% + 120px) calc(100% + 160px);
    --btm-left: -2% calc(100% + 160px);
    clip-path: polygon(var(--top-left), var(--top-right), var(--btm-right), var(--btm-left));
}

.hero-image {
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0;
    object-fit: cover;
}

.hero-content {
    flex: 0.8;
    padding-right: 5rem;
    margin-left: 2.5cm;
    max-width: 35%;
}

.hero-content h1 {
    font-size: 2.25rem;
    margin: 0;
    color: inherit;
    font-family: inherit;
    font-weight: 200;
    line-height: 1.2;
    margin-bottom: 1rem;
}

.hero-content .location {
    display: block;
    font-size: 1rem;
    margin-bottom: 25px;
}

.hero-content p {
    font-family: 'Oscine', sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(97, 97, 97);
}





/* Facility Masterplan Section */
.section-wrapper {
    margin-top: 6cm;
    padding: 0px 0px 135px;
    display: flex;
    justify-content: center;
    align-items: center;
 
    background-color:rgb(240, 240, 240);
}

.section-content {
    max-width: 45%;
    text-align: justify;
    margin-top: -5cm;
}

.section-content p {
    font-family: 'Oscine', sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(97, 97, 97);
    margin: 0;
}

@media screen and (max-width: 1439px) {
    .section-content {
        max-width: 45%;
        padding-right: 4rem;
    }
}




/* Project Section */
.eeeNzp {
    
    
    padding: 1cm 4cm;
}

.bbJFvu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 90px;
    padding: 2cm;
    gap: 1.5cm;
}

.bbJFvu.reverse {
    flex-direction: row-reverse;
    margin-top: 1.5cm;
}

.eBZEyY {
    padding: 0;
    margin: 0 auto;
    width: 100%;
}

.ratio-img-wrapper-sc-8qesjk-1,
.ratio-img-wrapper-sc-8qesjk-2 {
   height: 435px;
    max-width: 580px;

    transition: transform 0.3s ease;
}

.ratio-img-wrapper-sc-8qesjk-1:hover,
.ratio-img-wrapper-sc-8qesjk-2:hover {
    transform: scale(1.1);
}

.project-image-copyblock-content-sc-di9xrj-1,
.project-image-copyblock-content-sc-di9xrj-2 {
    width: 100%;
    margin-right: 0.5cm;
    padding-left: 0.5cm;
    font-family: Oscine, sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    color: rgb(97, 97, 97);
    margin-bottom: 3CM;
}

.project-image-copyblock-content-sc-di9xrj-2 {
    max-width: 100%;

}



/* Project Section Mobile Responsiveness */
@media screen and (max-width: 880px) {
    .eeeNzp {
        
        padding: 1rem 0.5rem;
    }

    .bbJFvu,
    .bbJFvu.reverse {
        flex-direction: column;
        align-items: var(--top-left);
        gap: 1rem;
        margin-bottom: 2rem;
        width: 100%;
        text-align: left;
    }

    .ratio-img-wrapper-sc-8qesjk-1,
    .ratio-img-wrapper-sc-8qesjk-2 {
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
    }

    .dwEpAz,
    .dwEpAz2 {
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
    }

    .project-image-copyblock-content-sc-di9xrj-1,
    .project-image-copyblock-content-sc-di9xrj-2 {
        width: 100%;
        margin: 0;
        padding: 0;
        font-size: 1rem;
        text-align: left;
       
    }
}










/* Related Projects Section */
.uniqueBeNxCw {
    background-color: rgb(240, 240, 240);
    margin-top: 20pxm;
    padding: 50px;
}

.uniqueEBZEyY {
    padding: 0 var(--container-padding);
    max-width: var(--container-width);
    margin-left: 4cm;
}

.uniqueECjdAA {
    display: inline-block;
    min-width: 650px;
    padding-right: 150px;
    padding-bottom: 20px;
    border-bottom: 2px solid rgb(0, 0, 0);
    margin-bottom: 50px;
}

.uniqueWOhqI {
    position: relative;
    display: grid;
    column-gap: 10px;
    grid-template-columns: repeat(22, 1fr);
    margin-top: 95px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
    color: inherit;
    font-family: inherit;
    font-weight: 200;
    line-height: 1.2;
    font-size: 3.5em;
}

.uniqueDZUreZ {
    grid-column: span 10;
}

a,
button {
    cursor: pointer;
}

a {
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.uniqueDWepAz {
    box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
    --top-left: -20% -20%;
    --top-right: 100% -20%;
    --btm-right: 78% calc(100% + 160px);
    --btm-left: -120px calc(100% + 160px);
    clip-path: polygon(var(--top-left), var(--top-right), var(--btm-right), var(--btm-left));
    height: 340px;
    width: 540px;
    object-fit: cover;
}

.uniqueBlhpmN {
    position: relative;
}

.uniqueStwfE {
    font-size: 2.25rem;
}

.uniqueJxeAbl {
    position: relative;
    display: inline-block;
    line-height: 1;
    font-weight: 400;
    color: rgb(0, 0, 0);
    text-decoration: none;
    margin-top: 20px;
}




























/* Mobile Responsiveness */
/* Mobile Responsiveness */
@media screen and (max-width: 880px) {
    .project-hero {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem 1rem;
        margin-left: 0;
        margin-bottom: 2rem;
        height: auto;
    }

    .hero-image-container {
        flex: 1;
        width: 100%;
        margin-right: 0;
        height: auto;
        box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
        --top-left: 15% 0;
        --top-right: calc(100% + 120px) 0;
        --btm-right: calc(100% + 120px) calc(100% + 160px);
        --btm-left: -2% calc(100% + 160px);
        clip-path: polygon(var(--top-left), var(--top-right), var(--btm-right), var(--btm-left));
        /* Remove the shadow for a cleaner look */
    }

    .hero-image {
        width: 100%;
        height: auto;
        object-fit: cover;
        position: relative;
    }

    .hero-content {
        max-width: 100%;
        margin-left: 0;
        padding-right: 0;
        text-align: left;
    }

    .hero-content h1 {
        margin-top: 4rem;
        font-size: 1.75rem;
        margin-bottom: 0.5rem;
    }

    .hero-content p {
        font-size: 1rem;
    }

    .section-wrapper {
        flex-direction: column;
        align-items: flex-start;
        padding: 1rem;
        margin-top: 1rem;
    }

    .section-content {
        max-width: 100%;
        padding-right: 0;
        margin-top: 0.5rem;
        text-align: left;
        font-size: 1rem;
    }

    .uniqueBeNxCw {
        margin-top: 1rem;
        padding: 20px;
    }

    .uniqueEBZEyY {
        margin-left: 0;
        padding: 0 10px;
    }

    .uniqueECjdAA {
        padding-right: 0;
        min-width: 100%;
        border-bottom: 1px solid rgb(0, 0, 0);
        margin-bottom: 20px;
        text-align: left;
    }

    .uniqueWOhqI {
        grid-template-columns: 1fr;
        margin-top: 30px;
        row-gap: 15px;
    }

    .uniqueFLhVRG {
        font-size: 1.75rem;
    }

    .uniqueDZUreZ {
        grid-column: span 1;
    }

    .uniqueDWepAz {
        height: 180px;
        width: 100%;
        object-fit: cover;
    }

    .uniqueStwfE {
        font-size: 1.5rem;
    }

    .uniqueJxeAbl {
        margin-top: 8px;
        font-size: 1rem;
    }
}
