/* Footer Section */
.footer {
    background-color: white;
    color: black;
    padding: 40px 0;
    text-align: center;
    border: 4px solid;
    border-image: linear-gradient(to right, #66B8D9, #C9D952) 1;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.footer-logo img {
    max-width: 150px;
}

.footer-address {
    max-width: 250px;
    margin: 20px;
    flex: 1;
}

.footer-address p {
    margin: 0;
    line-height: 1.6;
    font-size: 1rem;
    color: black;
}

.footer-address a {
    color: #459DBF;
    text-decoration: none;
}

.footer-address a:hover {
    text-decoration: underline;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .footer-container {
        justify-content: space-between;
    }

    .footer-address {
        max-width: 200px;
        margin: 15px;
    }

    .footer-logo img {
        max-width: 120px;
    }
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-address {
        max-width: 100%;
        margin: 10px 0;
    }

    .footer-logo img {
        max-width: 100px;
    }
}

@media (max-width: 480px) {
    .footer-container {
        padding: 20px;
    }

    .footer-address p {
        font-size: 0.875rem;
        /* Smaller font size on mobile */
        line-height: 1.4;
    }

    .footer-logo img {
        max-width: 80px;
    }
}
