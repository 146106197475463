/* src/components/Header.css */

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    background-color: white;
    z-index: 1200;
    height: 70px;
}

.appbar {
    box-shadow: none;
    z-index: 1100;
    height: 70px;
    background-color: #66b8d9;
}

.logo img {
    height: 50px;
    max-width: 100%;
}

.social-media-icons {
    display: flex;
    gap: 10px;
    margin-left: auto;
    margin-right: 1rem;
}

.social-media-icons a {
    color: black;
    font-size: 1.2rem;
    transition: color 0.3s ease;
}

.social-media-icons a:hover {
    color: #459dbf;
}

.menu-icon {
    font-size: 2rem;
    color: #4b4947;
}

.nav-container {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background-color: white;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.4s ease-out;
    z-index: 2000;
}

.nav-container.open {
    max-height: 300vh;
    transition: max-height 0.4s ease-in;
}

.nav-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-item {
    margin: 15px 0;
    font-size: 1.25rem;
}

/* Responsive Styles for Mobile */
@media screen and (max-width: 768px) {
    .logo img {
        height: 40px;
        /* Reduce logo size on smaller screens */
    }

    .social-media-icons a {
        font-size: 1rem;
        /* Reduce icon size on smaller screens */
    }

    .menu-icon {
        font-size: 1.8rem;
        /* Slightly reduce menu icon size on smaller screens */
    }
}

@media screen and (max-width: 480px) {
    .logo img {
        height: 35px;
        /* Further reduce logo size on very small screens */
    }

    .social-media-icons a {
        font-size: 0.9rem;
        /* Further reduce icon size on very small screens */
    }

    .menu-icon {
        font-size: 1.6rem;
        /* Further reduce menu icon size on very small screens */
    }
}





/* Responsive Styles for Mobile */
@media screen and (max-width: 768px) {
    .logo img {
        height: 40px;
    }

    .social-media-icons a {
        font-size: 1rem;
    }

    .menu-icon {
        font-size: 1.8rem;
    }

    .header {
        padding: 0 8px;
    }

    .nav-item {
        font-size: 1.15rem;
    }
}

@media screen and (max-width: 480px) {
    .logo img {
        height: 35px;
    }

    .social-media-icons a {
        font-size: 0.9rem;
    }

    .menu-icon {
        font-size: 1.6rem;
    }

    .header {
        padding: 0 5px;
    }

    .nav-item {
        font-size: 1rem;
    }
}





/* AboutHero.css */
.AboutHero__StyledAboutHero {
    width: 100%;
    height: auto;
    overflow: hidden;
    position: relative;
    padding: 0;
    margin: 0;
}

.HeroMedia__VideoWrap {
    position: relative;
    width: 100%;
    height: 100%;
}

.HeroMedia__Video {
    width: 100%;
    height: auto;
    object-fit: cover;
}

/* Responsive styling for mobile devices */
@media screen and (max-width: 880px) {
    .AboutHero__StyledAboutHero {
        padding: 0;
        margin: 0;
    }

    .HeroMedia__Video {
        margin-top: 0;
        width: 100%;
        height: auto;
        object-fit: cover;
    }

    .HeroMedia__VideoWrap {
        text-align: left;
    }

    .AboutHero__StyledAboutHero h1,
    .AboutHero__StyledAboutHero p {
        font-size: 1.5rem;
        line-height: 1.2;
    }
}

@media screen and (max-width: 480px) {
    .HeroMedia__Video {
        object-fit: contain;
    }

    .AboutHero__StyledAboutHero h1,
    .AboutHero__StyledAboutHero p {
        font-size: 1.2rem;
        line-height: 1.2;
    }
}

/* AboutStory.css */
.about-story {
    padding: 4rem 10px;
    max-width: 100%;
    margin: 4rem auto;
    background-color: #f5f5f5;
    box-sizing: border-box;
}

.container {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 4rem;
    align-items: start;
}

.story-heading {
    margin-top: 1cm;
    font-size: 4.75rem;
    color: inherit;
    font-family: inherit;
    font-weight: 50;
    line-height: 1.2;
    margin-left: 2rem;
    padding-right: 1rem;
    margin-bottom: 3rem;
}

.story-content {
    font-family: Oscine, sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 2.0;
    color: rgb(97, 97, 97);
    margin-left: 8rem;
    margin-right: 8rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    max-width: 100%;
    box-sizing: border-box;
}

/* Responsive adjustments */
@media screen and (max-width: 1079px) {
    .container {
        grid-template-columns: 1fr;
    }

    .story-heading {
        font-size: 3rem;
        text-align: center;
        margin-left: 0;
        padding-right: 0;
        margin-bottom: 3rem;
    }

    .story-content {
        margin-left: 2rem;
        margin-right: 2rem;
        text-align: center;
        max-width: 100%;
        margin-top: 2rem;
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 768px) {
    .about-story {
        padding: 2rem 10px;
    }

    .story-heading {
        font-size: 2.5rem;
        margin-bottom: 2.5rem;
    }

    .story-content {
        font-size: 1rem;
        max-width: 100%;
        line-height: 1.8;
        margin-left: 2rem;
        margin-right: 2rem;
        margin-top: 2rem;
        margin-bottom: 2.5rem;
    }
}

@media screen and (max-width: 480px) {
    .story-heading {
        font-size: 2rem;
        margin-bottom: 2rem;
    }

    .story-content {
        font-size: 0.9rem;
        margin-bottom: 2rem;
    }
}






/* AboutImage.css */
/* AboutImage.css */
.about-story-images {
    margin-top: 8cm;
    position: relative;
    max-width: 1200px;
    margin-right: auto;
    margin-left: 0;
    padding: 0;
    height: auto;
    box-sizing: border-box;
}

.main-image img {
    width: 100%;
    height: auto;
    display: block;
    margin: 0;
    padding: 0;
}

.angled-image {
    position: absolute;
    transform-origin: center;
}

.angled-image-1 {
    top: -7cm;
    left: 6cm;
    width: 500px;
    height: 385px;
    z-index: 2;
    clip-path: polygon(0 0, 100% 0, 85% 100%, 0 100%);
}

.angled-image-2 {
    width: 500px;
    height: 385px;
    bottom: 3cm;
    right: -25%;
    box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
    clip-path: polygon(15% 0, calc(100% + 120px) 0, calc(100% + 120px) calc(100% + 160px), -2% calc(100% + 160px));
    z-index: 1;
}

/* Hide angled images on smaller screens */
@media screen and (max-width: 768px) {
    .angled-image {
        display: none;
        /* Hides both angled images */
    }

    .about-story-images {
        margin-top: 4cm;
    }

    .main-image img {
        width: 100%;
        height: auto;
    }
}



/* OurMission.css */
.our-mission {
    margin-top: 4cm;
    padding: 4rem;
    background-color: #f0f4f8;
}

.our-mission h1 {
    font-size: 4.375rem;
    font-weight: 200;
    margin-bottom: 2rem;
    text-align: center;
    color: #333;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.mission-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    perspective: 1000px;
}

.mission-column {
    background: linear-gradient(135deg, white,
        rgba(162, 191, 57, 0.8));
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
    transform: translateZ(0);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.mission-column:hover {
    transform: translateZ(20px) translateY(-10px);
    box-shadow: 0 12px 25px rgba(0, 0, 0, 0.5);
}

.mission-column h3 {
    font-size: 1.75rem;
    margin-bottom: 1rem;
    color: #003366;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
}

.mission-column p {
    font-family: 'Oscine', sans-serif;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.6;
    color: #666;
}

@media screen and (max-width: 768px) {
    .our-mission h1 {
        font-size: 3.75rem;
    }

    .mission-grid {
        grid-template-columns: 1fr;
        gap: 1.5rem;
    }

    .mission-column {
        padding: 1.5rem;
    }

    .mission-column h3 {
        font-size: 1.5rem;
    }

    .mission-column p {
        font-size: 1rem;
    }
}

@media screen and (max-width: 480px) {
    .our-mission {
        padding: 2rem;
    }

    .our-mission h1 {
        font-size: 3rem;
        margin-bottom: 1.5rem;
    }

    .mission-column {
        padding: 1rem;
    }

    .mission-column h3 {
        font-size: 1.375rem;
    }

    .mission-column p {
        font-size: 0.875rem;
    }
}






/* OurProcess.css */
.our-process {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 10px;
    max-width: 1200px;
    margin: 0 auto;
    box-sizing: border-box;
}

.text-content {
    flex: 1;
    padding-right: 40px;
    box-sizing: border-box;
}

.text-content h1 {
    font-size: 4rem;
    font-weight: 200;
    margin-bottom: 20px;
    line-height: 1.2;
}

.text-content p {
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 1.6;
    color: #333;
    margin-bottom: 20px;
}

.image-content {
    flex: 1;
    position: relative;
}

.image-content .main-image {
    width: 100%;
    height: auto;
    display: block;
    margin-bottom: 20px;
}

.image-content .secondary-image {
    position: absolute;
    bottom: -10px;
    margin-left: 10cm;
    width: 50%;
    box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
    clip-path: polygon(-20% -20%, 100% -20%, 58% calc(100% + 160px), -120px calc(100% + 160px));
}

@media screen and (max-width: 768px) {
    .our-process {
        flex-direction: column;
        padding: 30px 10px;
    }

    .text-content {
        padding-right: 0;
        text-align: left;
    }

    .text-content h1 {
        font-size: 3rem;
        text-align: center;
    }

    .text-content p {
        font-size: 1rem;
        text-align: center;
    }

    .image-content {
        width: 100%;
        margin-top: 20px;
        position: relative;
    }

    .image-content .main-image {
        width: 100%;
        height: auto;
        margin-bottom: 1rem;
    }

    .image-content .secondary-image {
        position: absolute;
        margin-top: 4cm;
        bottom: -1cm;
        margin-left: 4cm;
        margin-right: 1cm;
        width: 50%;
        height: auto;
        box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
        clip-path: polygon(-20% -20%, 100% -20%, 58% calc(100% + 160px), -120px calc(100% + 160px));
    }
}

@media screen and (max-width: 480px) {
    .text-content h1 {
        font-size: 2.5rem;
        text-align: center;
    }

    .text-content p {
        font-size: 0.9rem;
        text-align: center;
    }

    .image-content .secondary-image {
        width: 50%;
        height: auto;
        bottom: -1cm;
        margin-left: 4cm;
        margin-right: 1cm;
        box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
        clip-path: polygon(-20% -20%, 100% -20%, 58% calc(100% + 160px), -120px calc(100% + 160px));
    }
}


/* services-image.css */
.services-section {
    text-align: center;
    padding: 60px 10px;
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
    box-sizing: border-box;
}

.services-title {
    font-size: 4rem;
    font-weight: 200;
    margin-bottom: 40px;
    color: #333;
    font-family: inherit;
    line-height: 1.2;
}

.services-bars {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.service-bar {
    width: 70px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    border-radius: 8px;
    margin: 0 5px;
}

/* Updated color palette */
.green-bar {
    background-color: #CCD96C;
    /* Updated color */
}

.light-green-bar {
    background-color: #8BBBD9;
    /* Updated color */
}

.mint-bar {
    background-color: #C9D955;
    /* Updated color */
}

.blue-bar {
    background-color: #B5BF65;
    /* Updated color */
}

.light-blue-bar {
    background-color: #91A644;
    /* Updated color */
}

.sky-blue-bar {
    background-color: #D7D9A0;
    /* Updated color */
}

.extra-bar-1 {
    background-color: #66CAF2;
    /* Extra color from palette */
}

.extra-bar-2 {
    background-color: #296573;
    /* Extra color from palette */
}

@media screen and (max-width: 768px) {
    .services-title {
        font-size: 2.5rem;
        text-align: left;
        padding-left: 10px;
    }

    .services-bars {
        flex-wrap: nowrap;
        overflow-x: auto;
        justify-content: flex-start;
        padding: 10px 0;
    }

    .service-bar {
        width: 60px;
        height: 300px;
        font-size: 0.875rem;
        margin: 0 4px;
    }
}


/* WorkWithUs.css */
.work-with-us {
    padding: 50px 10px 125px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1cm;
    box-sizing: border-box;
}

.work-with-us-container {
    display: flex;
    max-width: 1200px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.work-with-us-image img {
    width: 100%;
    height: 100%;
    max-width: 500px;
    object-fit: cover;
    box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
    clip-path: polygon(-20% -20%, 100% -20%, 58% calc(100% + 160px), -120px calc(100% + 160px));
}

.work-with-us-content {
    max-width: 600px;
    text-align: left;
    padding-left: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.work-with-us-heading {
    font-size: 3.75rem;
    font-weight: 200;
    margin-bottom: 20px;
    text-align: center;
    line-height: 1.2;
}

.work-with-us-text {
    font-size: 1.25rem;
    line-height: 1.6;
    margin-bottom: 20px;
    color: #333;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
}

.work-with-us-text li {
    margin-bottom: 0.2rem;
}

.work-with-us-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #91A644;
    color: #fff;
    text-decoration: none;
    font-size: 1.125rem;
    font-weight: 500;
    margin-top: 40px;
    text-align: center;
    width: 30%;
    transition: background-color 0.3s;
    margin-left: 3.5cm;
}

.work-with-us-button:hover {
    background-color: #91A644;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
    .work-with-us-container {
        flex-direction: column;
        align-items: flex-start;
        padding: 10px;
        /* Reduced padding for smaller screens */
    }

    .work-with-us-image img {
        max-width: 90%;
        /* Increased max-width for better visibility */
        margin-bottom: 30px;
    }

    .work-with-us-content {
        padding-left: 0;
        align-items: flex-start;
        text-align: left;
    }

    .work-with-us-heading {
        font-size: 2.5rem;
        text-align: left;
        margin-left: 0;
        padding-left: 10px;
        /* Aligned with the container */
    }

    .work-with-us-text {
        grid-template-columns: 1fr;
        /* Stack items vertically */
        gap: 5px;
    }

    .work-with-us-button {
        font-size: 1rem;
        padding: 10px 15px;
        width: 100%;
        /* Full width for better alignment */
        margin-left: 0;
    }
}

@media screen and (max-width: 480px) {
    .work-with-us-container {
        padding: 5px;
        /* Minimal padding for very small screens */
    }

    .work-with-us-heading {
        font-size: 2rem;
        padding-left: 5px;
    }

    .work-with-us-text {
        font-size: 1rem;
    }

    .work-with-us-button {
        font-size: 0.875rem;
        padding: 8px 12px;
        width: 100%;
        /* Full width for small devices */
    }
}





/* OurClients.css */
.our-clients {
    padding: 80px 10px;
    background: #f5f5f5;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
}

.clients-heading {
    font-size: 3.75rem;
    font-weight: 200;
    margin-bottom: 60px;
    line-height: 1.2;
}

.clients-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin: 0 -115px;
    text-align: center;
}

.client-name {
    display: inline-block;
    color: rgb(201, 201, 201);
    font-size: 2.25rem;
    font-weight: 200;
    line-height: 1.7;
    white-space: nowrap;
    margin: 0 10px;
}

.client-name.highlight {
    color: rgb(0, 0, 0);
    font-weight: 200;
    transition: opacity 0.35s ease-in;
}

@media screen and (max-width: 1379px) {
    .clients-heading {
        font-size: 3rem;
    }

    .client-name {
        font-size: 1.75rem;
        line-height: 1.7;
    }
}

@media screen and (max-width: 1024px) {
    .clients-heading {
        font-size: 2.5rem;
        margin-bottom: 50px;
    }

    .client-name {
        font-size: 1.5rem;
        margin: 0 8px;
    }

    .clients-row {
        margin: 0 -90px;
    }
}

@media screen and (max-width: 768px) {
    .clients-heading {
        font-size: 2.25rem;
        margin-bottom: 40px;
    }

    .client-name {
        font-size: 1.25rem;
        margin: 0 6px;
    }

    .clients-row {
        margin: 0 -60px;
    }
}

@media screen and (max-width: 480px) {
    .clients-heading {
        font-size: 2rem;
        margin-bottom: 30px;
    }

    .client-name {
        font-size: 1rem;
        margin: 0 5px;
    }

    .clients-row {
        margin: 0 -40px;
    }
}







/* Footer.css */
.footer {
    background-color: white;
    color: black;
    padding: 40px 0;
    text-align: center;
    border: 4px solid;
    border-image: linear-gradient(to right, #66B8D9, #C9D952) 1;
}

.footer-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
}

.footer-logo img {
    max-width: 150px;
}

.footer-address {
    max-width: 250px;
    margin: 20px;
    flex: 1;
}

.footer-address p {
    margin: 0;
    line-height: 1.6;
    font-size: 1rem;
    color: black;
}

.footer-address a {
    color: #459DBF;
    text-decoration: none;
}

.footer-address a:hover {
    text-decoration: underline;
}

@media (max-width: 1024px) {
    .footer-container {
        justify-content: space-between;
    }

    .footer-address {
        max-width: 200px;
        margin: 15px;
    }

    .footer-logo img {
        max-width: 120px;
    }
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .footer-address {
        max-width: 100%;
        margin: 10px 0;
    }

    .footer-logo img {
        max-width: 100px;
    }
}

@media (max-width: 480px) {
    .footer-container {
        padding: 20px;
    }

    .footer-address p {
        font-size: 0.875rem;
        line-height: 1.4;
    }

    .footer-logo img {
        max-width: 80px;
    }
}
