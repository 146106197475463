.fakLAl {
    position: relative;
    display: grid;
    column-gap: 40px;
    /* Adjust the gap between image and content */
    grid-template-columns: repeat(22, 1fr);
    padding: 0 var(--container-padding);
    max-width: var(--container-width);
    margin-top: 4cm;
    
    /* Keep the top margin as is */
}

.btAWOx {

    /* General Container Adjustments */
    .contact-page-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 20px;
        
    }

    .kQgmzP {
        display: flex;
        flex-direction: column;
        gap: 40px;
        width: 100%;
        max-width: 1200px;
        margin: 20px 0;
        
    }

    .GsYIv.ContactForm {
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin-top: 30px;
        
    }







    position: relative;
    grid-column: span 9;
    align-self: flex-start;
    margin-bottom: 25%;
    margin-left: 110px;
    /* Keep left margin */
    margin-top: 120px;
    /* Maintain top margin */
}


.cwGKJh {
    box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
    --top-left: -20% -20%;
    --top-right: 100% -20%;
    --btm-right: 58% calc(100% + 160px);
    --btm-left: -120px calc(100% + 160px);
    clip-path: polygon(var(--top-left), var(--top-right), var(--btm-right), var(--btm-left));
    position: relative;
    z-index: 1;
    /* Ensure this image is above others */
}

.iJkwdP {
    position: relative;
    width: 540px;
    height: 665px;
    margin-bottom: 20px;
}

.isoNtD {
    box-shadow: rgba(0, 0, 0, 0.12) -30px 35px 60px 0px;
    --top-left: 15% 0;
    --top-right: calc(100% + 120px) 0;
    --btm-right: calc(100% + 120px) calc(100% + 160px);
    --btm-left: -2% calc(100% + 160px);
    clip-path: polygon(var(--top-left), var(--top-right), var(--btm-right), var(--btm-left));
    position: absolute;
    right: -30%;
    /* Keep the distance from the content */
    bottom: -20%;
    width: 510px;
    height: 384px;
    z-index: 2;
    /* Ensure this image appears on top */
}

.bHipPv {
    position: relative;
    width: 100%;
    height: 100%;
    
}

.bHipPv .RatioImg__Img-sc-8qesjk-0 {
    height: 100%;
    width: 100%;
    position: absolute;
    inset: 0px;
    object-fit: cover;
    
}

.kQgmzP {
    grid-column: 13 / -1;
    align-self: center;
    justify-self: start;
    margin-top: 40px;
    /* Keep top margin to align with the image section */
    margin-left: 20px;
    /* Adjust space between the form and the content div */
    display: flex;
    flex-direction: column;
    gap: 40px;
    
 
    /* Ensure consistent spacing between .fynJbO and .GsYIv.ContactForm */
}

.GsYIv.ContactForm {
    margin-top: 30px;
    /* Adjust the top margin of the form to create consistent spacing */
    padding: 0;
}

.fynJbO {
    margin-bottom: 40px;
    /* Ensure consistent spacing between content and the form */
}

.StwfE {
    font-size: 2.25rem;
}

.ffqjhh {
    margin-top: 12px;
    color: rgb(97, 97, 97);
    font-family: Oscine, sans-serif;
    font-style: normal;
}

.dsLYPx {
    color: rgb(97, 97, 97);
    font-family: Oscine, sans-serif;
    vertical-align: middle;
    display: flex;
    align-items: center;
}

.ezXYZL {
    position: absolute;
    top: 0px;
    left: -999px;
    clip: rect(0px, 0px, 0px, 0px);
    pointer-events: none;
}

.ContactForm__StyledInput-sc-14p1k92-1.eBIHJq {
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 1px solid rgb(0, 0, 0);
    border-radius: 0px;
    appearance: none;
    padding: 10px 0px;
    font-size: 1.125rem;
    font-family: inherit;
    font-weight: inherit;
    margin-bottom: 25px;
     background-color: #f5f5f5;
}

.ContactForm__StyledTextArea-sc-14p1k92-3.fhDgCJ {
    width: 100%;
    border-top: none;
    border-right: none;
    border-left: none;
    border-image: initial;
    border-bottom: 1px solid rgb(0, 0, 0);
    border-radius: 0px;
    appearance: none;
    padding: 10px 0px;
    font-size: 1.125rem;
    font-family: inherit;
    font-weight: inherit;
    resize: none;
    background-color: #f5f5f5;
}

.ggJyiH.ggJyiH {
    background-color: #9F9F9F;
    color: rgb(255, 255, 255);
    font-family: Oscine, sans-serif;
    font-size: 0.875rem;
    text-align: center;
    transition: background-color 500ms;
    padding: 20px 60px;
    text-align: left;
}

.gBMAvg {
    margin-top: 45px;
}

.Vucbf {
    padding: 0px;
    font-size: inherit;
    font-weight: inherit;
    font-family: inherit;
    appearance: none;
    background-color: transparent;
    border: 0px;
    text-align: inherit;
}











.hidden-door-section {
    text-align: center;
    margin-top: 50px;
    background-color: #F2F2F2;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
}

.section-header {
    display: inline-block;
    border-bottom: 2px solid #C9D955;
    color: #4b4947;
    font-family: inherit;
    font-weight: 200;
    line-height: 1.2;
    font-size: 2.5rem;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.hidden-door-container {
    position: relative;
    width: 900px;
    height: 600px;
    margin: 0 auto;
    border: 2px solid #D9D5D2;
    overflow: hidden;
    perspective: 2000px;
    background-color: #F2F2F2;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.door {
    position: absolute;
    width: 100%;
    height: 100%;
    background: url('../images/contactus/3.jpg') no-repeat center center;
    background-size: cover;
    /* Ensures the image covers the entire door */
    transition: transform 1s ease;
    cursor: pointer;
    transform-origin: left;
    transform-style: preserve-3d;
    z-index: 2;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.door-handle {
    width: 20px;
    height: 50px;
    background-color: #B9B9B9;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.door.open {
    transform: rotateY(-75deg);
    box-shadow: -10px 10px 20px rgba(0, 0, 0, 0.3);
}

.card-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: scale(0);
    transition: transform 0.5s ease, opacity 0.5s ease;
    opacity: 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-container.visible {
    transform: scale(1);
    opacity: 1;
}

.card-image {
    width: 80%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease;
    transform-style: preserve-3d;
    background-color: #A2BF39;
}

.zoom-controls {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
}

.zoom-controls button {
    margin: 0 10px;
    padding: 8px 12px;
    background-color: #A2BF39;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.zoom-controls button:hover {
    background-color: #D9D5D2;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

















/* Color Palette */
:root {
    --color-heading: #333333;
    /* Dark Ash for heading */
    --color-text: #99A637;
    /* Olive Green for text */
    --color-container: #D9D5D2;
    /* Light Ash for container */
    --color-primary: #459DBF;
    /* Blue */
    --color-secondary: #A2BF39;
    /* Light Green */
    --color-tertiary: #99A637;
    /* Olive Green */
    --color-quaternary: #B7BF7A;
    /* Beige Green */
}







.contact-us {
    font-family: inherit;
    background-color: var(--color-container);
    color: var(--color-text);
    padding: 80px;
    text-align: center;
}

.contact-us h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
    border-bottom: 2px solid var(--color-text);
    color: var(--color-heading);
    display: inline-block;
}

.contact-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 20px;
    justify-content: center;
}

.contact-item {
    text-decoration: none;
    color: var(--color-text);
    transition: transform 0.3s ease-in-out;
}

.contact-item:hover {
    transform: scale(1.1);
}

.contact-icon-container {
    width: 100px;
    height: 100px;
    margin: 0 auto 10px;
    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.3) -8px 8px 20px, rgba(0, 0, 0, 0.2) -12px 12px 25px, rgba(0, 0, 0, 0.1) -16px 16px 30px;
    --top-left: -20% -20%;
    --top-right: 100% -20%;
    --btm-right: 78% calc(100% + 160px);
    --btm-left: -120px calc(100% + 160px);
    clip-path: polygon(var(--top-left), var(--top-right), var(--btm-right), var(--btm-left));
    transition: transform 0.3s ease-in-out;
}

.contact-icon-container:nth-child(2n) {
    background-color: var(--color-secondary);
}

.contact-icon-container:nth-child(3n) {
    background-color: var(--color-tertiary);
}

.contact-icon-container:nth-child(4n) {
    background-color: var(--color-quaternary);
}

.contact-item:hover .contact-icon-container {
    transform: scale(1.1);
}

.contact-icon {
    font-size: 2rem;
    color: #fff;
}

.contact-item p {
    font-size: 1.2rem;
    margin: 0;
    color: var(--color-text);
}

/* Responsive adjustments for mobile devices */
@media screen and (max-width: 768px) {
    .fakLAl {
        grid-template-columns: 1fr;
        column-gap: 20px;
        margin-top: 1.5cm;
        padding: 0 10px;
    }

    .btAWOx {
        grid-column: 1 / -1;
        margin-left: 0;
        margin-top: 20px;
        margin-bottom: 15%;
        padding: 0 10px;
    }

    .kQgmzP {
        grid-column: 1 / -1;
        align-self: flex-start;
        margin-top: 20px;
        margin-left: 0;
        gap: 40px;
        display: flex;
        flex-direction: column;
    }

    /* Removed Image Adjustments */
    .cwGKJh,
    .iJkwdP,
    .isoNtD {
        display: none;
    }

    /* Hide the hidden door section on mobile for better layout */
    .hidden-door-section {
        display: none;
    }



    /* Contact Us Section */
    .contact-us {
        padding: 40px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .contact-us h2 {
        font-size: 2rem;
        margin-bottom: 20px;
    }

    .contact-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 10px;
    }

    .contact-item p {
        font-size: 1rem;
       
    }

    .contact-icon-container {
        width: 60px;
        height: 60px;
    }

    .contact-icon {
        font-size: 1.5rem;
    }
}

/* Extra small devices (phones) */
@media screen and (max-width: 480px) {
    .fakLAl {
        margin-top: 1.5cm;
        padding: 0 5px;
    }

    .btAWOx {
        margin-left: 0;
        margin-top: 15px;
        margin-bottom: 10%;
        padding: 0 5px;
    }

    .kQgmzP {
        gap: 20px;
        margin-top: 20px;
    }

    /* Removed Image Adjustments */
    .cwGKJh,
    .iJkwdP,
    .isoNtD {
        display: none;
    }

    /* Contact Us Section */
    .contact-us {
        margin-top: 3cm;
        padding: 30px 15px;
    }

    .contact-us h2 {
        font-size: 1.75rem;
        margin-bottom: 15px;
    }

    .contact-grid {
        grid-template-columns: repeat(3, 1fr);
        gap: 5px;
    }

    .contact-item p {
        font-size: 0.875rem;
    }

    .contact-icon-container {
        width: 50px;
        height: 50px;
    }

    .contact-icon {
        font-size: 1.25rem;
    }
}
